










import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator'

import { QrcodeStream } from 'vue-qrcode-reader'

@Component({
  name: 'ReturnScan',
  components: {
    QrcodeStream
  }
})
export default class extends Vue {
  onDecode(link: string) {
    window.location.href = link;
  }

  get take() {
    return !!this.$route.query.take;
  }
}
